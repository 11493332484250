import {
  BrowserCacheLocation,
  Configuration,
  InteractionRequiredAuthError,
  PublicClientApplication,
} from '@azure/msal-browser'
import { EnvironmentVariable, getEnvironment } from '@helpers/environment'
import { getHostOrigin } from '@helpers/origin'
import * as graph from '@microsoft/microsoft-graph-client'
import { documentSortAndNest } from './GraphHelpers'

const redirectUri = getHostOrigin()

const msalBaseConfig: Configuration = {
  auth: {
    clientId: getEnvironment().getString(EnvironmentVariable.AZURE_APP_ID),
    redirectUri: redirectUri,
  },
  cache: {
    // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/caching.md
    // Localstorage is shared between tabs
    cacheLocation: BrowserCacheLocation.LocalStorage, // This configures where your cache will be stored

    storeAuthStateInCookie: true,
  },
}

export function getAuthorityUrlFromInstance(authority: 'AzurePublic' | 'USGovernment') {
  switch (authority) {
    case 'AzurePublic':
      return `https://login.microsoftonline.com/common`
    case 'USGovernment':
      return `https://login.microsoftonline.us/common`
  }
}

export const scopes = [
  'openid',
  'profile',
  'email',
  'User.Read',
  'Sites.Read.All',
  // 'Mail.Read',
  // 'Contacts.ReadWrite',
  // 'Directory.Read.All',
  // 'OrgContact.Read.All',
]

const PUBLIC_AUTHORITY = `https://login.microsoftonline.com/common`

function createAzureClient(endpoint?: string): PublicClientApplication | undefined {
  const authority = endpoint ?? PUBLIC_AUTHORITY

  const config = { ...msalBaseConfig }
  config.auth.authority = authority

  if (process.browser) {
    const client = new PublicClientApplication(config)
    return client
  }
}

function initializeAzureClient(client: PublicClientApplication | undefined) {
  if (client) client.initialize()
  if (typeof window !== 'undefined') {
    ;(window as any).azureClient = client
  }
}

function getAzureClient(): PublicClientApplication {
  if (typeof window === 'undefined') {
    throw new Error('getAzureClient() called on server')
  }

  return (window as any).azureClient as PublicClientApplication
}

export function setAzureClientAuthority(endpoint: string) {
  console.log("Setting Azure client's authority to: ", endpoint)
  const client = createAzureClient(endpoint)
  initializeAzureClient(client)
}

// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/acquire-token.md
export async function getToken(silentOnly = false) {
  const request = {
    scopes: scopes,
    // authority: `https://login.microsoftonline.com/${TENANT_ID}`,
  }
  const client = getAzureClient()
  try {
    console.log('Attempting to acquire sharepoint token silently')
    const silentResult = await client.acquireTokenSilent(request)

    console.log('Silent token success.')
    return silentResult.accessToken
  } catch (err) {
    if (!silentOnly && err instanceof InteractionRequiredAuthError) {
      console.log('Silent token error, requires interaction, showing popup')
      const interactiveResult = await client.acquireTokenPopup(request)

      console.log('Interaction success.')
      return interactiveResult.accessToken
    } else {
      console.log('Error acquiring token: ', err)
      return false
    }
  }
}

export async function getTokenLoginPopup() {
  console.log("Attempting to acquire token with 'loginPopup'")
  const client = getAzureClient()
  const loginResult = await client.loginPopup({
    scopes: scopes,
    storeInCache: {
      accessToken: true,
      idToken: true,
      refreshToken: true,
    },
  })

  client.setActiveAccount(loginResult.account)

  return await getToken()
}

export function getGraphClient(token) {
  const client = graph.Client.init({
    authProvider: (done) => {
      done(null, token)
    },
  })

  return client
}

export const getUserDetails = async (token) => {
  const client = getGraphClient(token)

  const user = await client.api('/me').get()

  return user
}

export const checkUserAuthenticated = async () => {
  const token = await getToken()

  if (!token) {
    return false
  }
  return true
}

export const searchSpSites = async (query = '') => {
  const token = await getToken()

  if (!token) {
    return false
  }

  const client = await getGraphClient(token)

  const sites = await client.api(`/sites?search=${query}`).get()

  return sites.value
}

export const getSpSiteItems = async (spId) => {
  const items: Array<{ driveName: string; items: any[] }> = []
  const token = await getToken()

  if (!token) {
    return false
  }

  const client = await getGraphClient(token)

  const lists = await client.api(`/sites/${spId}/lists`).get()
  // console.log('lists: ', lists)

  const docLists = lists.value.filter((l) => l?.list?.template == 'documentLibrary')
  // console.log('docLists: ', docLists)

  if (!docLists) {
    return []
  }

  for (const dList of docLists) {
    const listItems = await getListDocs(client, spId, dList.id)
    // console.log('listItems: ', listItems)

    items.push({
      driveName: dList?.name,
      items: listItems,
    })
  }

  return items
}

const getListDocs = async (client, spId, listId) => {
  const items = await client.api(`/sites/${spId}/lists/${listId}/items?expand=fields`).get()
  // console.log('items: ', items)

  const sortedDocuments = documentSortAndNest(items.value)
  // console.log('sortedDocuments: ', sortedDocuments)

  const transformedDocs = sortedDocuments.map((sd) => {
    if (sd?.length !== 2) {
      return []
    }

    const element = sd[1] as any
    const doc = {
      id: element?.id,
      webUrl: element?.webUrl,
      createdAt: element?.createdDateTime,
      lastModified: element?.lastModifiedDateTime,
      name: element?.fields?.LinkFilename,
      docType: element?.fields?.DocIcon,
    }

    return [sd[0], doc]
  })

  return transformedDocs
}

export const getSpSiteListItem = async (spId, listId, itemId) => {
  const token = await getToken()

  if (!token) {
    return false
  }

  const client = await getGraphClient(token)

  const item = await client.api(`/sites/${spId}/lists/${listId}/items/${itemId}`).get()
  // console.log('items: ', items)

  return item
}

export const getSpSiteDriveItem = async (spId, driveItemId) => {
  const token = await getToken()

  if (!token) {
    return false
  }

  const client = await getGraphClient(token)

  const item = await client.api(`/sites/${spId}/drive/items/${driveItemId}`).get()
  // console.log('items: ', items)

  return item
}
